.customer-dashboard .ag-root-wrapper {
    border: none
}

.customer-dashboard .ag-row {
    border-bottom: 1px solid #e6e6e6;
}
.customer-dashboard .variant-table .ag-header  {
    background-color: transparent !important;
    border-top: none;
}

.withoutBorder .ag-root-wrapper {
    border: none
}

.withoutBorder .ag-row {
    border-bottom: 1px solid #e6e6e6;
}
.withoutBorder .variant-table .ag-header  {
    background-color: transparent !important;
    border-top: none;
}