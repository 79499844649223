.variant-table .ag-header {
  background-color: #f5f5f5 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.variant-table .ag-theme-alpine .ag-header-row {
  font-weight: 500 !important;
  color: #000 !important;
}

.variant-table .ag-root-wrapper {
  border: none !important;
}

.variant-table .ag-cell {
  white-space: normal;
  line-height: initial;
}
.table-pagination-padding-right .MuiStack-root{
  padding-right: 12px !important;
}

