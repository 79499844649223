@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Roboto:wght@400;500&display=swap");

a {
  text-decoration: none;
  color: #535151;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="password"]::-ms-reveal {
  display: none;
}
input[type="text"]::-ms-clear {
  display: none;
}
.ag-header-cell-text {
  font-size: 15px !important;
}
/* Tinymce Editor */
.tox-tinymce {
  border: 1px solid #dbdfea !important;
  border-radius: 4px !important;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  border-bottom: 1px solid #dbdfea !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.tox .tox-toolbar-overlord {
  border-top: 1px solid #dbdfea !important;
  padding: 0 !important;
}
.tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid #dbdfea !important;
}

/* Dropzone upload */
.dropzone {
  background: #fff;
  border: 1px dashed #e5e9f2;
  border-radius: 4px;
  min-height: 150px;
  padding: 20px;
}
.dropzone.dz-clickable {
  cursor: pointer;
}
.dropzone .dz-message span {
  color: rgba(82, 100, 132, 0.6);
  display: block;
}
.dropzone .dz-message {
  margin: 1em 0;
  text-align: center;
}
.dropzone .dz-message-text {
  font-size: 13px;
}
.dropzone .dz-message-or {
  font-size: 16px;
  margin-bottom: 4px;
  text-transform: uppercase;
}
.dz-preview {
  border: 1px solid #dbdfea;
  border-radius: 5px;
}
.dropzone {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 18px;
}

/* ag-grid table */
.ag-header {
  background-color: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.ag-header-row:not(:first-child) .ag-header-cell,
.ag-header-row:not(:first-child)
  .ag-header-group-cell.ag-header-group-cell-with-group {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.ag-theme-alpine .ag-ltr input[class^="ag-"]:not([type]),
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="text"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="number"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="tel"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="date"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="datetime-local"],
.ag-theme-alpine .ag-ltr textarea[class^="ag-"],
.ag-theme-alpine-dark .ag-ltr input[class^="ag-"]:not([type]),
.ag-theme-alpine-dark .ag-ltr input[class^="ag-"][type="text"],
.ag-theme-alpine-dark .ag-ltr input[class^="ag-"][type="number"],
.ag-theme-alpine-dark .ag-ltr input[class^="ag-"][type="tel"],
.ag-theme-alpine-dark .ag-ltr input[class^="ag-"][type="date"],
.ag-theme-alpine-dark .ag-ltr input[class^="ag-"][type="datetime-local"],
.ag-theme-alpine-dark .ag-ltr textarea[class^="ag-"] {
  height: 35px;
}

.ag-center-cols-clipper {
  min-height: unset !important;
}
.ag-cell {
  font-family: "Montserrat";
  display: flex !important;
  align-items: center;
  font-size: 16px;
  line-height: 1.2;
}
.ag-cell a {
  color: initial;
}

.ag-header-cell-text {
  font-size: 16px;
  font-weight: 500 !important;
  color: #000;
}
.ag-row:last-child {
  border: none;
}
/*.ag-row-odd {*/
/*  background-color: #fff !important;*/
/*}*/
.table-wrapper .ag-root-wrapper {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 150px;
}
.routes-table .ag-cell {
  color: #535151;
}
.center-header .ag-header-cell-label {
  justify-content: center;
}
.routes-changes .ag-cell {
  align-items: flex-start;
  font-size: 14px;
  line-height: 16px;
}
.routes-changes .ag-cell p {
  font-size: 14px;
}
.routes-changes .ag-cell-value {
  padding-top: 5px;
  padding-bottom: 7px;
}

.ag-ltr .ag-floating-filter-button {
  display: none; /* for bug fix #321, and i think we don't need that option*/
}
.ag-header-icon.ag-header-cell-menu-button {
  display: none; /* for bug fix #321, and i think we don't need that option*/
}

/* MUI styles */
.MuiFormGroup-root[role="radiogroup"] {
  border: 1px solid #dbdfea;
  border-radius: 5px;
  padding: 0 10px;
}
.MuiFormGroup-root[role="radiogroup"] .MuiFormControlLabel-label {
  font-weight: 500;
}
.MuiTableRow-root td {
  background-color: #f6f6f7;
  border-left: 1px solid rgba(150, 150, 150, 0.25);
  font-size: 13px;
  white-space: nowrap;
  padding: 2px 8px;
}
.MuiTableRow-root td:last-child {
  text-align: center;
}
.MuiTableRow-root td:first-child {
  border-left: none;
  white-space: normal;
}
.MuiTableRow-root:last-child td {
  border-bottom: none;
}
.MuiTable-root {
  border-radius: 5px;
  overflow: hidden;
}
.MuiIconButton-root {
  min-width: 20px;
  padding: 3px !important;
}
.time-input {
  border: none;
  outline: 0;
}

.css-wyttaa-MuiStack-root {
  gap: 0 !important;
}

.MuiBox-root.css-1bgyaqg img[src]:not([src=""]) {
  padding-right: 4px;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-4xbbh5-MuiAutocomplete-root
  .MuiOutlinedInput-root,
.css-wb57ya-MuiFormControl-root-MuiTextField-root,
.css-1k99mlm-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1k99mlm-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  min-height: 46px;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1k99mlm-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding: inherit;
  padding-left: 5px;
}

.css-1k99mlm-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 0 0 5px;
}

.css-1bvctd4-MuiTypography-root {
  margin-bottom: 7px !important;
}

.css-r0cfvj-MuiGrid-root > .MuiGrid-item {
  padding: 5px;
}

.css-1k99mlm-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  padding: 3.5px 4px 7.5px 6px;
}

/* Dropdown select date */
.date-dropdown {
  font-family: "Montserrat";
  height: 46px;
  font-size: 16px;
  border: 1px solid #dbdfea;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  padding-right: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NjcuNSAxMDI0Ij48cGF0aCBkPSJtMCAzODQgMzgzLjc1IDM4My43NUw3NjcuNSAzODRIMHoiIGZpbGw9IiM3NTc1NzUiIGNsYXNzPSJmaWxsLTAwMDAwMCI+PC9wYXRoPjwvc3ZnPg==")
    no-repeat;
  background-position: right 11px top 50%;
  background-size: 13px 13px;
  transition: 0.2s ease;
  cursor: pointer;
}
.date-dropdown:hover {
  border-color: #b7b6be;
}
.date-dropdown:focus-visible {
  border-width: 1px;
  border-color: rgb(73, 0, 133);
  outline: 0;
}
.date-option {
  margin-left: 10px;
}

/* react-circular-progressbar */
[data-test-id]:hover svg.CircularProgressbar path.CircularProgressbar-path {
  stroke: #3fc6a5 !important;
}

/* snackbar on dialogs fix */
.notistack-SnackbarContainer.z-alert {
  z-index: 10000;
}

/* this code broke the markup of some inputs,   */

.btn-height {
  height: 41px;
}

.btn-height input {
  height: 100% !important;
  padding: 0px 12px !important;
}

.css-k8b0ti {
  align-items: flex-start;
}

/*.ag-cell-wrapper {*/
/*  font-size: 14px;*/
/*  padding-top: 5px;*/
/*  padding-bottom: 5px;*/
/*}*/

.MuiButton-root {
  height: 40px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root input {
  /* height: 100%; */
  padding: 8.5px 12px;
  /* min-height: 40px; */
}

.css-1qz3642-MuiFormLabel-root-MuiInputLabel-root {
  top: -8px !important;
}

.ag-input-field-input {
  height: 40px !important;
}

.date-picker .MuiTextField-root .MuiOutlinedInput-root {
  height: 100%;
}

/*@media (max-width: 480px) {*/
/*  .sx-full-width {*/
/*    width: 100%;*/
/*  }*/
/*}*/

.user-customers-table .ag-row:before {
  background-color: #eaf4fd;
  background-image: none;
}
.ag-center-cols-viewport {
  min-height: 250px !important;
}

.otp-input {
  width: 40px;
  height: 40px;
  font-size: 20px;
  border: 1px solid #dbdfea;
  border-radius: 5px;
  text-align: center;
  outline: none;
}
