.custom-spinner {
  height: 24px;
  width: 24px;
  border: 3px solid;
  border-color: #6576ff transparent #6576ff transparent;
  border-radius: 50%;
  animation: spin 1.4s linear infinite;
}

.css-1vob3wk-MuiTypography-root {
  text-transform: uppercase;
}

.css-1vyglni-MuiButtonBase-root-MuiButton-root, button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedError.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedError.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-10kegvd-MuiButtonBase-root-MuiButton-root {
  height: 41px;
}

.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root{
  margin: 0px 16px 16px 0px;
}

a.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-uxnivo-MuiButtonBase-root-MuiListItemButton-root {
  margin: 0;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 22px;
  height: 22px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
